<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>上传配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label-width="130px" label="图片存储方式" prop="status"><el-switch v-model="info.status" :active-text="info.status?'阿里OSS':'本地'"></el-switch></el-form-item>
                    <el-form-item label-width="130px" label="OssAccessKeyId" prop="access_key"><el-input placeholder="请输入内容" v-model="info.access_key"></el-input></el-form-item>
                    <el-form-item label-width="130px" label="OssSecretAccess" prop="secret_access"><el-input placeholder="请输入内容" v-model="info.secret_access"></el-input></el-form-item>
                    <el-form-item label-width="130px" label="OssBucket" prop="bucket"><el-input placeholder="请输入内容" v-model="info.bucket"></el-input></el-form-item>
                    <el-form-item label-width="130px" label="OssEndpoint" prop="endpoint"><el-input placeholder="请输入内容" v-model="info.endpoint"></el-input></el-form-item>
                    <el-form-item label-width="130px" label="注意："><el-tag type="info">Oss为阿里云平台</el-tag></el-form-item>
                    <el-form-item label-width="130px" >
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{
              status:false,
              access_key:'',
              secret_access:'',
              bucket:'',
              endpoint:'',
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.info.status = this.info.status?1:0;
                    this.$post(this.$api.uploadConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_upload_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_upload_config(){
            this.$get(this.$api.uploadConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                    this.info.status = this.info.status==1?true:false;
                }
            });
        },
    },
    created() {
        this.get_upload_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>